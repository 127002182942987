import { getEnums } from '@/utils/utils'
//待审批【政务版】
export const notApprovalColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    slots: { customRender: 'fileName' },
    ellipsis: true
    // width: 100
  },
  {
    title: '印章名称',
    dataIndex: 'sealNames',
    key: 'sealNames',
    align: 'left',
    slots: { customRender: 'sealNames' },
    ellipsis: true
    // width: 100
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    ellipsis: true
    // width: 100
  },
  // {
  //   title: '审批类型',
  //   dataIndex: 'examineType',
  //   key: 'examineType',
  //   align: 'left',
  //   slots: { customRender: 'examineType' },
  //   width: 100,
  // },
  {
    title: '预计用印日期',
    dataIndex: 'sealTime',
    key: 'sealTime',
    align: 'left',
    slots: { customRender: 'sealTime' }
  },
  {
    title: '提交申请时间',
    dataIndex: 'applyTime',
    key: 'applyTime',
    align: 'left',
    // width: 200,
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 150
  }
]
//待审批【企业版】
export const notApprovalColumn = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'processTopic',
    key: 'processTopic',
    align: 'left',
    slots: { customRender: 'processTopic' },
    width: 250
  },
  {
    title: '流程类型',
    dataIndex: 'processMold',
    key: 'processMold',
    slots: { customRender: 'processMold' },
    width: 150
  },
  {
    title: '流程名称',
    dataIndex: 'processName',
    key: 'processName',
    slots: { customRender: 'processName' },
    width: 200
  },
 
  {
    title: '申请人',
    dataIndex: 'applyStaffName',
    key: 'applyStaffName',
    slots: { customRender: 'applyStaffName' },
    ellipsis: true,
    width: 200
  },
  {
    title: '提交申请时间',
    dataIndex: 'applyTime',
    key: 'applyTime',
    align: 'left',
    width: 200,
    sorter: true
  },
  {
    title: '当前审批人',
    dataIndex: 'examineNames',
    key: 'examineNames',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'examineNames' },
    // width: 132
    width: 250
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right'
    // width: 150
  }
]
//已审批【企业版】
export const approvedColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'processTopic',
    key: 'processTopic',
    align: 'left',
    slots: { customRender: 'processTopic' },
    width: 300
  },
  {
    title: '流程类型',
    dataIndex: 'processMold',
    key: 'processMold',
    slots: { customRender: 'processMold' },
    width: 150
  },
  {
    title: '流程名称',
    dataIndex: 'processName',
    key: 'processName',
    slots: { customRender: 'processName' },
    width: 200
  },
  {
    title: '申请人',
    dataIndex: 'applyStaffName',
    key: 'applyStaffName',
    slots: { customRender: 'applyStaffName' },
    width: 145
  },
  {
    title: '审批状态',
    dataIndex: 'examineStatus',
    key: 'examineStatus',
    align: 'left',
    slots: { customRender: 'examineStatus' },
    width: 133
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 62
  }
]
//已审批【政务版】
export const approvedColumn = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    slots: { customRender: 'fileName' },
    ellipsis: true
    // width: 100
  },
  {
    title: '申请印章',
    dataIndex: 'sealNames',
    key: 'sealNames',
    align: 'left',
    slots: { customRender: 'sealNames' }
    // width: 100
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    ellipsis: true
    // width: 100
  },
  {
    title: '当前审批人',
    dataIndex: 'examineStaffName',
    key: 'examineStaffName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'examineStaffName' }
    // width: 100
  },
  // {
  //   title: '审批类型',
  //   dataIndex: 'examineType',
  //   key: 'examineType',
  //   align: 'left',
  //   slots: { customRender: 'examineType' },
  //   width: 100
  // },
  {
    title: '预计用印日期',
    dataIndex: 'sealTime',
    key: 'sealTime',
    align: 'left',
    slots: { customRender: 'sealTime' }
  },
  {
    title: '审批状态',
    dataIndex: 'examineStatus',
    key: 'examineStatus',
    align: 'left',
    slots: { customRender: 'examineStatus' }
    // width: 100
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 150
  }
]
//抄送我的【政务版】
export const informPeopleColumn = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    slots: { customRender: 'fileName' },
    ellipsis: true,
    width: 200
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    ellipsis: true,
    width: 150
  },
  {
    title: '申请印章',
    dataIndex: 'sealName',
    key: 'sealName',
    align: 'left',
    slots: { customRender: 'sealName' },
    ellipsis: true,
    width: 200
  },
  {
    title: '印章类型',
    dataIndex: 'sealTypeName',
    key: 'sealTypeName',
    align: 'left',
    slots: { customRender: 'sealTypeName' },
    ellipsis: true,
    width: 100
  },
  {
    title: '申请次数',
    dataIndex: 'applicationNumber',
    key: 'applicationNumber',
    align: 'left',
    slots: { customRender: 'applicationNumber' },
    ellipsis: true,
    width: 100
  },
  {
    title: '审批人',
    dataIndex: 'examineStaffName',
    key: 'examineStaffName',
    align: 'left',
    ellipsis: true,
    width: 150
  },
  {
    title: '抄送时间',
    dataIndex: 'carbonCopyTime',
    key: 'carbonCopyTime',
    align: 'left',
    slots: { customRender: 'carbonCopyTime' },
    width: 200,
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 100
  }
]
//抄送我的【企业版】
export const informPeopleColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'processTopic',
    key: 'processTopic',
    align: 'left',
    slots: { customRender: 'processTopic' },
    // ellipsis: true,
    width: 250
  },

  {
    title: '流程类型',
    dataIndex: 'processMold',
    key: 'processMold',
    align: 'left',
    slots: { customRender: 'processMold' },
    // ellipsis: true,
    width: 135
  },
  {
    title: '流程名称',
    dataIndex: 'processName',
    key: 'processName',
    // ellipsis: true,
    width: 200,
    slots: { customRender: 'processName' }
  },
  {
    title: '申请人',
    dataIndex: 'applyStaffName',
    key: 'applyStaffName',
    ellipsis: true,
    width: 200,
    slots: { customRender: 'applyStaffName' }

  },
  {
    title: '审批人',
    dataIndex: 'examineNames',
    key: 'examineNames',
    ellipsis: true,
    width: 200
  },
  {
    title: '抄送时间',
    dataIndex: 'applyTime',
    key: 'applyTime',
    align: 'left',
    slots: { customRender: 'applyTime' },
    width: 186,
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 62
  }
]
